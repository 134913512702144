import { setCMSBarWidth } from './cms-bar-width'


import './select_checkbox'
import './required_checkbox'
import { commercialTable } from './commercial_table'
import { initMenuSticky } from './menu_sticky'
import { initMenuControl } from './menu_control'

document.addEventListener('DOMContentLoaded', () => {
    // Because message 'Enter a valid email address.' is hidden.
    for (const email of document.querySelectorAll('.base-form input[type=email].has-error')) {
        if (email.value) {
            email.classList.add('email-error')
        }

        email.addEventListener('input', () => {
            email.classList.remove('email-error')
        })
    }

    for (const svg of document.querySelectorAll('.cooperation aside img')) {
        svg.insertAdjacentHTML('afterend', `<div class="info-tooltip" >${svg.alt}</div>`)
    }

    // Open according collapse
    for (const collapse of document.querySelectorAll('.collapse-box')) {
        if (collapse.querySelector('*[id*="open"') && !window.location.hash) {
            collapse.querySelector('header[id^=trigger-open]').classList.remove('collapsed')
            collapse.querySelector('header[id^=trigger-open]').setAttribute('aria-expanded', true)
            collapse.querySelector('aside[id^=open]').classList.add('collapse', 'show')
        }
    }

    for (const item of document.querySelectorAll('.prevent-default')) {
        item.href = ''
    }

    // Add subject for link with mailto
    for (const link of document.querySelectorAll('a[href^="mailto"]')) {
        if (link.dataset.subject) {
            link.href = `${link.href}?subject=${link.dataset.subject}`
        }
    }

    // Open first nav tab, nav pills
    for (const tabs of document.querySelectorAll('ul[role="tablist"][class*="nav-"].nav')) {
        tabs.querySelector('.nav-item button').click()
    }

    // If url has hash try to open collapse
    if (document.getElementById(`${window.location.hash.replace('#', '')}`)) {
        document.getElementById(`${window.location.hash.replace('#', '')}`).click()
    }

    // Open navigation items in a new tab
    for (const link of document.querySelectorAll('.page-navigation a[href]')) {
        if (!link.href.includes(window.location.host)) {
            link.setAttribute('target', '_blank')
        }
    }

    commercialTable()
    initMenuSticky()
    initMenuControl()

    const structure = document.querySelector("a[href='?structure']")
    if (structure) {
        structure.addEventListener('click', () => {setTimeout(setCMSBarWidth, 1000)}, true)
    }

    const toolbar_link = document.querySelector(".cms-toolbar-item-logo a")
    if (toolbar_link) {
        toolbar_link.after(document.body.dataset.project_version)
    }
})

addEventListener("resize", commercialTable)

import './cms_toolbar'
